import LazyLevenHeader from '../components/LazyLevenHeader'
import ImageGrid from '../components/ImageGrid'
import Announcement from '../components/Announcement'
import { liveImageUrl } from '../api/liveImage'

const cameras = ['LL1', 'LL2', 'LL3', 'LL4']
const liveImages = cameras.map(liveImageUrl)
const HomePage = () => {
  return (
    <div className='HomePage'>
      <LazyLevenHeader />
      <Announcement />
      <ImageGrid images={liveImages} />
    </div>
  )
}

export default HomePage
