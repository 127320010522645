import LazyLevenHeader from '../components/LazyLevenHeader'
import Announcement from '../components/Announcement'
import { useContext } from 'react'
import { ProfileContext } from '../contexts/ProfileContext'
import { Navigate } from 'react-router-dom'

const ProfilePage = () => {
  const profile = useContext(ProfileContext)
  if (!profile) {
    return <Navigate to='/' replace />
  }

  return (
    <div className='HomePage'>
      <LazyLevenHeader />
      <Announcement />
      <hr className='event-break' />
      <p>
        You are currently signed in as <b>{profile.name}</b> (<i>{profile.email}</i>).
      </p>
    </div>
  )
}

export default ProfilePage
