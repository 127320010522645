import LazyLevenHeader from '../components/LazyLevenHeader'
import LoginComponent from '../components/LoginComponent'

const LoginPage = () => {
  return (
    <div>
      <LazyLevenHeader />
      <LoginComponent />
    </div>
  )
}

export default LoginPage
