import { useContext, useState } from 'react'
import './SignupComponent.css'
import './LoginComponent.css'
import { ProfileContext, ProfileDispatchContext } from '../contexts/ProfileContext'
import ErrorBanner from './ErrorBanner'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { createUser } from '../api/auth'
import { validateEmail, validatePassword } from '../utils'

const SignupComponent = () => {
  const profile = useContext(ProfileContext)
  const profileDispatch = useContext(ProfileDispatchContext)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showRePassword, setShowRePassword] = useState(false)
  const [error, setError] = useState('')

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleRePasswordChange = (event) => {
    setRePassword(event.target.value)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const toggleRePasswordVisibility = () => {
    setShowRePassword(!showRePassword)
  }

  const attemptSignUp = () => {
    if (!validateEmail(email)) {
      setError('Email is not valid.')
      return
    }

    if (password !== rePassword) {
      setError('Passwords do not match.')
      return
    }

    if (!validatePassword(password)) {
      setError(
        'Password must be at least 12 characters, include at least one uppercase, one lowercase, and one number.'
      )
      return
    }

    setPassword('')
    setRePassword('')
    createUser(
      name,
      email,
      password,
      (response) => {
        profileDispatch({
          type: 'added',
          profile: {
            name: name,
            email: email,
            accessToken: response.access_token,
            refreshToken: response.refresh_token
          }
        })
      },
      (e) => setError('Error during signup.')
    )
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      attemptSignUp()
    }
  }

  const handleClick = (event) => {
    event.preventDefault()
    attemptSignUp()
  }

  const clearError = () => {
    setError('')
  }

  if (profile) {
    return <h2>You have successfully signed up.</h2>
  }

  return (
    <div className='login-container'>
      <div className='login-box'>
        <p>Name</p>
        <div className='login-field-container'>
          <input
            className='login-field'
            value={name}
            onChange={handleNameChange}
            onKeyDown={handleKeyDown}
            required
          />
        </div>
        <p>Email</p>
        <div className='login-field-container'>
          <input
            className='login-field'
            type='email'
            name='email'
            autoComplete='email'
            value={email}
            onChange={handleEmailChange}
            onKeyDown={handleKeyDown}
            required
          />
        </div>
        <p>Password</p>
        <div className='password-field-container'>
          <input
            className='password-field'
            type={showPassword ? 'text' : 'password'}
            name='password'
            autoComplete='new-password'
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handleKeyDown}
            required
          />
          <i onClick={togglePasswordVisibility}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </i>
        </div>
        <p>Re-enter Password</p>
        <div className='password-field-container'>
          <input
            className='password-field'
            type={showRePassword ? 'text' : 'password'}
            name='repassword'
            autoComplete='new-password'
            value={rePassword}
            onChange={handleRePasswordChange}
            onKeyDown={handleKeyDown}
            required
          />
          <i onClick={toggleRePasswordVisibility}>
            {showRePassword ? <FaEyeSlash /> : <FaEye />}
          </i>
        </div>
        <p></p>
        <button className='login-button' onClick={handleClick}>
          Sign up
        </button>
      </div>
      {error && (
        <div className='login-box'>
          <ErrorBanner message={error} clearError={clearError} />
        </div>
      )}
    </div>
  )
}

export default SignupComponent
