import LazyLevenHeader from '../components/LazyLevenHeader'
import SignupComponent from '../components/SignupComponent'

const SignupPage = () => {
  return (
    <div>
      <LazyLevenHeader />
      <SignupComponent />
    </div>
  )
}

export default SignupPage
