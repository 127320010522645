import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import './LazyLevenHeader.css'
import lazyLevenSign from '../assets/images/lazy_leven_sign_v1.jpg'
import lazyLeven2Sign from '../assets/images/lazy_leven_2_sign_v1.jpg'
import ProfileComponent from './ProfileComponent'
import { getProfileRole, ProfileContext } from '../contexts/ProfileContext'

const LazyLevenHeader = () => {
  const profile = useContext(ProfileContext)
  const role = getProfileRole(profile)
  // const elevationInfoRoles = ["restricted"]
  // TODO: implement this
  const elevationInfoRoles = []
  const timelapseRoles = ['restricted', 'user', 'admin']

  return (
    <div className='lazy-leven-header'>
      <ul>
        <div className='left-group'>
          <li className='logo'>
            <Link to='/'>
              <div className='logo-container'>
                <img
                  className='lazy-leven-sign-img'
                  alt='lazy leven sign old'
                  src={lazyLevenSign}
                />
                <img
                  className='lazy-leven-sign-img-hover'
                  alt='lazy leven sign new'
                  src={lazyLeven2Sign}
                />
              </div>
            </Link>
          </li>
          {elevationInfoRoles.includes(role) && (
            <li>
              <Link to='/elevation_info/'>Want to view timelapses?</Link>
            </li>
          )}
          {timelapseRoles.includes(role) && (
            <li className='dropdown'>
              <span>Timelapses</span>
              <div className='dropdown-content'>
                <Link to='/timelapse/LL1/latest/'>Culvert Timelapse</Link>
                <Link to='/timelapse/LL2/latest/'>Stream Timelapse</Link>
                <Link to='/timelapse/LL3/latest/'>North Timelapse</Link>
                <Link to='/timelapse/LL4/latest/'>Shed Timelapse</Link>
              </div>
            </li>
          )}
          <li>
            <Link to='/events'>Events</Link>
          </li>
        </div>
        <ProfileComponent />
      </ul>
    </div>
  )
}

export default LazyLevenHeader
