import { useState, useRef, useEffect, useContext } from 'react'
import './VideoPlayer.css'
import { getVideoViews } from '../api/video'
import { ProfileContext } from '../contexts/ProfileContext'

function VideoPlayer({ src }) {
  const profile = useContext(ProfileContext)
  const accessTokenStr = profile ? `?access_token=${profile.accessToken}` : ''
  const [playbackRate, setPlaybackRate] = useState(1)
  const [playbackRateIndex, setPlaybackRateIndex] = useState(7)
  const [views, setViews] = useState(null)
  const videoRef = useRef(null)

  const availableSpeeds = [
    0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.8, 1.0, 1.25, 1.5, 1.75, 2.0, 2.5, 3.0, 4.0, 5.0,
    6.0, 8.0, 10.0, 13.0, 16.0
  ]

  useEffect(() => {
    getVideoViews(src, setViews)
  }, [src])

  const handleIncreaseSpeed = () => {
    const isAtMax = playbackRateIndex + 1 === availableSpeeds.length
    if (!isAtMax) {
      setPlaybackRate(availableSpeeds[playbackRateIndex + 1])
      setPlaybackRateIndex(playbackRateIndex + 1)
      document.querySelector('video').playbackRate =
        availableSpeeds[playbackRateIndex + 1]
    }
  }

  const handleDecreaseSpeed = () => {
    const isAtMin = playbackRateIndex === 0
    if (!isAtMin) {
      setPlaybackRate(availableSpeeds[playbackRateIndex - 1])
      setPlaybackRateIndex(playbackRateIndex - 1)
      document.querySelector('video').playbackRate =
        availableSpeeds[playbackRateIndex - 1]
    }
  }

  return (
    <div className='video-container'>
      <video
        className='video-player'
        ref={videoRef}
        src={src + accessTokenStr}
        playbackrate={playbackRate}
        controls
      />
      <div className='controls-container'>
        <div className='button-container'>
          <button className='button' onClick={handleIncreaseSpeed}>
            Speed Up
          </button>
          <button className='button' onClick={handleDecreaseSpeed}>
            Slow Down
          </button>
        </div>
        <p className='playback-rate'>Speed: {playbackRate}x</p>
        {views !== null && <p className='view-count'>Views: {views}</p>}
      </div>
    </div>
  )
}

export default VideoPlayer
