import config from '../config'
import axios from 'axios'

export const getVideoViews = (videoSrc, callback) => {
  axios
    .get(`${videoSrc}/views/?increment=true`)
    .then((response) => {
      callback(response.data.number_views)
    })
    .catch(callback(0))
}
