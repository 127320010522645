import { createContext } from 'react'
import { jwtDecode } from 'jwt-decode'

export const ProfileContext = createContext(null)
export const ProfileDispatchContext = createContext(null)
export const getStoredProfile = () => {
  const email = localStorage.getItem('email')
  if (email === null) {
    return null
  } else {
    const name = localStorage.getItem('name')
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    return {
      email: email,
      name: name,
      accessToken: accessToken,
      refreshToken: refreshToken
    }
  }
}
export const profileReducer = (profile, action) => {
  switch (action.type) {
    case 'added': {
      localStorage.setItem('email', action.profile.email)
      localStorage.setItem('name', action.profile.name)
      localStorage.setItem('accessToken', action.profile.accessToken)
      localStorage.setItem('refreshToken', action.profile.refreshToken)
      return action.profile
    }
    case 'access_token_refresh': {
      localStorage.setItem('accessToken', action.accessToken)
      profile.accessToken = action.accessToken
      return profile
    }
    default: {
      localStorage.removeItem('email')
      localStorage.removeItem('name')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      return null
    }
  }
}

export const getProfileRole = (profile) => {
  if (profile && profile.accessToken) {
    const decodedAccessToken = jwtDecode(profile.accessToken)
    console.log(decodedAccessToken)
    if ('role' in decodedAccessToken) {
      console.log('role found')
      return decodedAccessToken.role
    }
  }
  return null
}
