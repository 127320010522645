import { useContext } from 'react'
import { ProfileDispatchContext } from '../contexts/ProfileContext'
import LazyLevenHeader from '../components/LazyLevenHeader'

const LogoutPage = () => {
  const profileDispatch = useContext(ProfileDispatchContext)

  profileDispatch({ type: 'removed' })

  return (
    <div>
      <LazyLevenHeader />
      <h2>You have successfully logged out.</h2>
    </div>
  )
}

export default LogoutPage
