import { useParams } from 'react-router-dom'
import LazyLevenHeader from '../components/LazyLevenHeader'
import VideoPlayer from '../components/VideoPlayer'
import config from '../config'

const VideoPage = () => {
  const path = useParams()['*']
  const camera = path.split('/')[0]
  const date = path.split('/')[1]
  const videoSource = `${config.apiUrl}/lazy_leven/_raw_/timelapse/${camera}/${date}`

  return (
    <div>
      <LazyLevenHeader />
      <h3>
        {camera} {date}
      </h3>
      <VideoPlayer src={videoSource} />
    </div>
  )
}

export default VideoPage
