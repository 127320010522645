const envConfig = {
  dev: {
    apiUrl: 'http://localhost:25567'
  },
  prod: {
    apiUrl: 'https://api.grantbaker.io'
  }
}

export const environment = process.env.REACT_APP_ENV
const config = envConfig[environment]
export default config
