import { Link } from 'react-router-dom'
import React, { useContext } from 'react'
import { ProfileContext } from '../contexts/ProfileContext'

const ProfileComponent = () => {
  const profile = useContext(ProfileContext)

  if (profile !== null) {
    return (
      <div>
        <li>
          <Link to='/profile'>
            <b>{profile.name}</b>
          </Link>
        </li>
        <li>
          <Link to='/logout'>Log Out</Link>
        </li>
      </div>
    )
  } else {
    return (
      <li>
        <Link to='/login'>Log In</Link>
      </li>
    )
  }
}

export default ProfileComponent
