import LazyLevenHeader from '../components/LazyLevenHeader'
import EventLink from '../components/EventLink'
import './EventsPage.css'

const EventsPage = () => {
  const eventData = [
    {
      date: '2023-10',
      text: 'Culvert Placement',
      url: '/timelapse/LL1/2023-10_culvert_placement/',
      _break: false
    },
    {
      date: '2023-10',
      text: 'Culvert Construction Timelapse',
      url: '/timelapse/LL1/2023-10_culvert_construction/',
      _break: true
    },
    {
      date: '2022-11-25',
      text: 'Flood Remediation Album',
      url: 'https://photos.app.goo.gl/xnidG2X6Tfhbt7C1A',
      _break: true
    },
    {
      date: '2022-11-16',
      text: 'Stream Freezing',
      url: '/timelapse/LL2/2022-11-16_stream_freeze/',
      _break: true
    },
    {
      date: '2022-10-27',
      text: 'First Snow!',
      url: '/timelapse/LL3/2022-10-27_first_snow/',
      _break: true
    },
    {
      date: '2022-08-15',
      text: 'Major Flood - Culvert View - Full',
      url: '/timelapse/LL1/2022-08-15_flood_full/',
      _break: false
    },
    {
      date: '2022-08-15',
      text: 'Major Flood - Stream View - Full',
      url: '/timelapse/LL2/2022-08-15_flood_full/',
      _break: true
    },
    {
      date: '2022-08-13',
      text: 'Two Floods - Stream View - Timelapse',
      url: '/timelapse/LL2/2022-08-13_two_floods/',
      _break: true
    },
    {
      date: '2022-07-31',
      text: 'Flood - Stream View',
      url: '/timelapse/LL2/2022-07-31_flood_fast/',
      _break: true
    },
    {
      date: '2022-07-29',
      text: 'Flood - Culvert View - Timelapse',
      url: '/timelapse/LL1/2022-07-29_flood_timelapse/',
      _break: false
    },
    {
      date: '2022-07-29',
      text: 'Flood - Stream View - Timelapse',
      url: '/timelapse/LL2/2022-07-29_flood_timelapse/',
      _break: false
    },
    {
      date: '2022-07-29',
      text: 'Flood - Culvert View - Full',
      url: '/timelapse/LL1/2022-07-29_flood_full/',
      _break: true
    },
    {
      date: '2022-07-15',
      text: 'Major Flood - Photo Album',
      url: 'https://photos.app.goo.gl/BdvkqwyiUUDWxZiX6',
      _break: false
    },
    {
      date: '2022-07-15',
      text: 'Major Flood - Culvert View',
      url: '/timelapse/LL1/2022-07-15_flood/',
      _break: false
    },
    {
      date: '2022-07-15',
      text: 'Major Flood - Stream View',
      url: '/timelapse/LL2/2022-07-15_flood/',
      _break: true
    },
    {
      date: '2022-06-29',
      text: 'mini-flood',
      url: '/timelapse/LL2/2022-06-29_flood/',
      _break: true
    }
  ]
  return (
    <div className='events-page'>
      <LazyLevenHeader />
      <h1>Notable Events</h1>
      <hr className='event-break' />
      {eventData.map(({ date, text, url, _break }) => {
        return (
          <>
            <EventLink date={date} text={text} url={url} />
            {_break && <hr className='event-break' />}
          </>
        )
      })}
    </div>
  )
}

export default EventsPage
