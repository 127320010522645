import { Link } from 'react-router-dom'

const EventLink = ({ date, text, url }) => {
  return (
    <div>
      <Link to={url}>
        <p>
          <b>{date}:</b> {text}
        </p>
      </Link>
    </div>
  )
}

export default EventLink
