import React from 'react'

// const ImageGrid = ({ images }) => {
//   const [isWideScreen, setIsWideScreen] = React.useState(false);
//
//   const handleResize = () => {
//     setIsWideScreen(window.innerWidth >= 600);
//   };
//
//   React.useEffect(() => {
//     window.addEventListener('resize', handleResize);
//     handleResize();
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);
//
//   return (
//     <div style={{ display: 'flex', flexDirection: isWideScreen ? 'row' : 'column' }}>
//       {images.map((imageSrc, index) => (
//         <img
//           key={index}
//           src={imageSrc}
//           alt={`LL${index + 1}`}
//           style={{ width: '100%', height: 'auto', marginBottom: isWideScreen ? 0 : '1rem' }}
//         />
//       ))}
//     </div>
//   );
// };

const ImageGrid = ({ images }) => {
  return (
    <div style={{ padding: '1rem' }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(650px, 1fr))',
          gap: '1rem'
        }}
      >
        {images.map((imageSrc, index) => (
          <img
            key={index}
            src={imageSrc}
            alt={`LL${index + 1}`}
            style={{ width: '100%', height: 'auto' }}
          />
        ))}
      </div>
    </div>
  )
}
export default ImageGrid
