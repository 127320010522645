import { useContext, useState } from 'react'
import './LoginComponent.css'
import { ProfileContext, ProfileDispatchContext } from '../contexts/ProfileContext'
import { loginWithPassword } from '../api/auth'
import ErrorBanner from './ErrorBanner'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const LoginComponent = () => {
  const profile = useContext(ProfileContext)
  const profileDispatch = useContext(ProfileDispatchContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const attemptLogIn = () => {
    setPassword('')
    loginWithPassword(
      email,
      password,
      (response) => {
        profileDispatch({
          type: 'added',
          profile: {
            name: response.name,
            email: email,
            accessToken: response.access_token,
            refreshToken: response.refresh_token
          }
        })
      },
      (e) => setError('Invalid email or password.')
    )
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      attemptLogIn()
    }
  }

  const handleClick = (event) => {
    event.preventDefault()
    attemptLogIn()
  }

  const clearError = () => {
    setError('')
  }

  if (profile) {
    return <h2>You have successfully logged in.</h2>
  }

  return (
    <div className='login-container'>
      <div className='login-box'>
        <p>Email</p>
        <div className='login-field-container'>
          <input
            className='login-field'
            type='email'
            name='email'
            autoComplete='email'
            value={email}
            onChange={handleEmailChange}
            onKeyDown={handleKeyDown}
          />
        </div>
        <p>Password</p>
        <div className='password-field-container'>
          <input
            className='password-field'
            type={showPassword ? 'text' : 'password'}
            name='password'
            autoComplete='current-password'
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handleKeyDown}
          />
          <i onClick={togglePasswordVisibility}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </i>
        </div>
        <button className='login-button' onClick={handleClick}>
          Log in
        </button>
      </div>
      {error && (
        <div className='login-box'>
          <ErrorBanner message={error} clearError={clearError} />
        </div>
      )}
      <div className='login-box'>
        <p>Don't have an account yet?</p>
        <Link to='/signup'>Sign up</Link>
      </div>
    </div>
  )
}

export default LoginComponent
