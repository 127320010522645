import './App.css'
import HomePage from './pages/HomePage'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import VideoPage from './pages/VideoPage'
import LoginPage from './pages/LoginPage'
import SignupPage from './pages/SignupPage'
import LogoutPage from './pages/LogoutPage'
import { useEffect, useReducer } from 'react'
import {
  getStoredProfile,
  ProfileContext,
  ProfileDispatchContext,
  profileReducer
} from './contexts/ProfileContext'
import { jwtDecode } from 'jwt-decode'
import { refreshToken } from './api/auth'
import EventsPage from './pages/EventsPage'
import ProfilePage from './pages/ProfilePage'

const App = () => {
  const [profile, profileDispatch] = useReducer(profileReducer, {}, getStoredProfile)

  useEffect(() => {
    if (profile) {
      console.log('checking tokens')
      const decodedRefreshToken = jwtDecode(profile.refreshToken)
      const currentDate = new Date()
      const isExpired = decodedRefreshToken.exp * 1000 < currentDate.getTime()
      if (isExpired) {
        profileDispatch({ type: 'removed' })
      } else {
        const decodedAccessToken = jwtDecode(profile.accessToken)
        const isExpired = decodedAccessToken.exp * 1000 < currentDate.getTime()
        if (isExpired) {
          refreshToken(
            profile.refreshToken,
            (newAccessToken) => {
              profileDispatch({
                type: 'access_token_refresh',
                accessToken: newAccessToken
              })
            },
            () => profileDispatch({ type: 'removed' })
          )
        }
      }
    }
  })

  return (
    <ProfileContext.Provider value={profile}>
      <ProfileDispatchContext.Provider value={profileDispatch}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/timelapse/*' element={<VideoPage />} />
            <Route path='/events' element={<EventsPage />} />
            <Route path='/signup' element={<SignupPage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/logout' element={<LogoutPage />} />
            <Route path='/profile' element={<ProfilePage />} />
            <Route exact path='/lazy_leven' element={<Navigate to='/' replace />} />
          </Routes>
        </BrowserRouter>
      </ProfileDispatchContext.Provider>
    </ProfileContext.Provider>
  )
}

export default App
