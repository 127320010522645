import axios from 'axios'
import config from '../config'
import { sha256 } from '../utils'

const salt =
  '2ZUVrnYWrvpdCXHI7rfER_Wpmi86Aa6NPUyCoLc_LobMHg6aFa32Z5jbTvYzqdwuOeqcnYV1ElcPB7ihPsCjfA'

const hashPassword = async (password) => {
  return sha256(password + salt)
}

export const createUser = async (
  name,
  email,
  password,
  successCallback,
  failureCallback
) => {
  const hashedPassword = await hashPassword(password)

  const body = {
    name: name,
    email: email,
    password: hashedPassword
  }

  return axios
    .post(`${config.apiUrl}/user/`, body)
    .then((response) => {
      console.log(response)
      successCallback(response.data)
    })
    .catch(failureCallback)
}

export const loginWithPassword = async (
  email,
  password,
  successCallback,
  failureCallback
) => {
  const hashedPassword = await hashPassword(password)

  const body = {
    email: email,
    password: hashedPassword
  }

  return axios
    .post(`${config.apiUrl}/login/`, body)
    .then((response) => {
      console.log(response)
      successCallback(response.data)
    })
    .catch(failureCallback)
}

export const refreshToken = (refreshToken, successCallback, failureCallback) => {
  axios
    .post(
      `${config.apiUrl}/refresh/`,
      {},
      {
        headers: { Authorization: `Bearer ${refreshToken}` }
      }
    )
    .then((response) => {
      console.log(response)
      successCallback(response.data.access_token)
    })
    .catch(failureCallback)
}
