import './ErrorBanner.css'

const ErrorBanner = ({ message, clearError }) => (
  <div className='error-banner'>
    <p>{message}</p>
    <button className='error-banner-close' onClick={clearError}>
      X
    </button>
  </div>
)

export default ErrorBanner
