import React, { useContext } from 'react'
import { ProfileContext } from '../contexts/ProfileContext'

const Announcement = () => {
  const profile = useContext(ProfileContext)
  return (
    <div>
      <p>NEW 2024-06-22: Fixed a couple of bugs with account sign-up and sign-in</p>
      {!profile && <p>Please sign in to view timelapses.</p>}
    </div>
  )
}

export default Announcement
